
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { pInteger } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { TaskDetail } from '@/types/smartHouse'

@Component
export default class IrrigationPlanAdd extends Vue {
  @Prop() private showDialog!: boolean
  @Prop() private id!: string
  // 正整数校验，小于20
  numLessTen = function (rule: object, value: string, callback: Function) {
    const reg = /^[1-9][0-9]*$/
    if (!reg.test(value) || Number(value) < 1 || Number(value) > 20) {
      return callback(new Error('请输入1到20数值'))
    } else {
      return callback()
    }
  }

  private info: TaskDetail = {
    buildingId: '',
    planType: '1',
    planName: '',
    executeTime: ['', '', ''],
    intervalDays: '',
    keepLength: '',
    drought: '',
    roomIdList: []
  }

  droughtRules = function (rule: object, value: string, callback: Function) {
    const reg = /^[1-9][0-9]*$/
    if (value && !reg.test(value)) {
      return callback(new Error('请输入1-99的正整数'))
    } else {
      return callback()
    }
  }

  private rules = {
    planName: [
      { required: true, message: '请输入计划名称', trigger: ['blur', 'change'] }
    ],
    buildingId: [
      { required: true, message: '请选择喷灌区域', trigger: ['blur', 'change'] }
    ],
    roomIdList: [
      { required: true, message: '请选择关联房间', trigger: ['blur', 'change'] }
    ],
    // dateRange: [
    //   { required: true, message: '请选择计划时间段', trigger: ['blur', 'change'] }
    // ],
    'executeTime[0]': [
      { required: true, message: '请选择开启时间', trigger: ['blur', 'change'] }
    ],
    concurrentNum: [
      { required: true, message: '请输入电磁阀一次同时开启的个数，范围1个到3个', trigger: ['blur', 'change'] }
    ],
    intervalDays: [
      { required: true, message: '请输入喷灌频率', trigger: ['blur', 'change'] },
      { validator: pInteger, trigger: ['blur', 'change'] }
    ],
    keepLength: [
      { required: true, message: '请输入电磁阀开启时长，时长范围不超过20分钟', trigger: ['blur', 'change'] },
      { validator: this.numLessTen, trigger: ['blur', 'change'] }
    ],
    drought: [
      { required: true, message: '请输入喷灌启动阈值', trigger: ['blur'] },
      { validator: this.droughtRules, trigger: ['blur', 'change'] }
    ]
  }

  private buildList = []
  private roomList = []
  private buildingId = '1'
  private submitShow = false
  checked = true

  @Watch('showDialog')
  getshowDialog (val: string) {
    if (val && this.id) {
      this.getPlanDetail(this.id)
    }
  }

  created () {
    this.getBuildList()
  }

  // 清空状态和数值
  change (val: string) {
    ;(this.$refs.info as ElForm).resetFields()
    this.info.planType = val
  }

  getPlanDetail (id: string) {
    this.$axios.get(this.$apis.smartHouse.selectSqIrrigationPlanByPlanId, {
      planId: id
    }).then((res: any) => {
      this.change(res.planType)
      this.info = {
        planType: res.planType,
        planId: res.planId,
        planName: res.planName,
        buildingId: res.buildingId,
        roomIdList: res.roomId,
        projectId: res.projectId,
        executeTime: JSON.parse(res.executeTime),
        intervalDays: res.intervalDays,
        keepLength: res.keepLength,
        drought: res.drought
      }
      this.getRoomList()
    })
  }

  changeAll (val: boolean) {
    if (val) {
      // 1
      this.info.roomIdList = this.roomList.map((item: any) => { return item.roomId })
    } else {
      this.info.roomIdList = []
    }
  }

  changeRoom (val: Array<string>) {
    if (this.id === '') {
      if (this.roomList.length !== val.length) {
        this.checked = false
      } else {
        this.checked = true
      }
    }
  }

  // 楼栋
  getBuildList () {
    this.$axios.get(this.$apis.smartHouse.selectBuildingByList).then((res) => {
      this.buildList = res.list || []
    })
  }

  changeBuild () {
    this.info.roomIdList = []
    this.getRoomList()
  }

  // 房间
  getRoomList () {
    const params = { buildingId: this.info.buildingId }
    this.$axios.get(this.$apis.smartHouse.selectRoomByList, this.id === '' ? { ...params, notPlan: '1' } : params).then((res) => {
      this.roomList = res.list || []
      this.checked = true
      this.$nextTick(() => {
        this.id === '' && this.changeAll(this.checked)
      })
    })
  }

  onSubmit () {
    const executeTime = this.info.executeTime.filter((item: any) => !!item)
    ;(this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        const date = this.$dayjs().format('YYYY-MM-DD')
        executeTime.sort((a: string, b: string) => {
          return this.$dayjs(date + ' ' + a).valueOf() - this.$dayjs(date + ' ' + b).valueOf()
        })
        for (let i = 0; i < executeTime.length - 1; i++) {
          const start = this.$dayjs(date + executeTime[i + 1], 'HH:mm')
          const end = this.$dayjs(date + executeTime[i], 'HH:mm')
          const diffTime = start.diff(end)
          if (diffTime <= +this.info.keepLength * 60 * 1000) {
            this.$message.warning('喷灌开启时间间隔必须大于开启时长')
            return
          }
        }
        this.submitShow = true
        let info: TaskDetail = {
          planName: this.info.planName,
          planType: this.info.planType,
          executeTime: JSON.stringify(executeTime),
          intervalDays: this.info.intervalDays,
          keepLength: this.info.keepLength,
          drought: this.info.drought,
          buildingId: this.info.buildingId
        }
        if (this.info.planId) {
          info = { ...info, planId: this.info.planId, roomId: this.info.roomIdList }
        } else {
          info = { ...info, roomIdList: this.info.roomIdList }
        }
        const load = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        const url = this.id ? this.$apis.smartHouse.updateSqIrrigationPlan : this.$apis.smartHouse.insertSqIrrigationPlan
        this.$axios.post(url, {
          ...info
        }).then(() => {
          load.close()
          this.$message({ message: '保存成功', type: 'success' })
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  closeDialog () {
    this.roomList = []
    this.info.executeTime = ['', '', '']
    this.info.planId = ''
    this.info.roomIdList = []
    this.$nextTick(() => {
      ;(this.$refs.info as ElForm).resetFields()
    })
    this.$emit('update:showDialog', false)
  }
}
