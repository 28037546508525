
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

  @Component
export default class IrrigationTimingDetail extends Vue {
    @Prop() private showDialog!: boolean
    @Prop() private id!: string
    private info = {
      planType: '1',
      planName: '',
      areaId: '',
      projectId: '',
      dateRange: [],
      executeTime: '',

      concurrentNum: '',
      intervalDays: '',
      keepLength: '',
      drought: ''
    }

    @Watch('id')
    getIsShow (val: string) {
      this.getPlanDetail(val)
    }

    getPlanDetail (id: string) {
      this.$axios.get(this.$apis.smartHouse.selectSqIrrigationPlanByPlanId, {
        planId: id
      }).then((res) => {
        this.info = res
      })
    }

    close () {
      this.$emit('update:showDialog', false)
    }
}
