
import { Component, Vue } from 'vue-property-decorator'
import IrrigationPlanAdd from './IrrigationPlanAdd.vue'
import IrrigationPlanDetail from './IrrigationPlanDetail.vue'

@Component({
  name: 'SoilList',
  components: {
    IrrigationPlanAdd,
    IrrigationPlanDetail
  }
})
export default class extends Vue {
    private searchInfo = {
      roomId: '',
      buildingId: this.$route.query.buildingId || ''
    }

    private page = 1
    private size = 10
    private total = 0
    private tableData =[]
    private loading = false
    private showDialog = false
    private showDialog1 = false
    private id = ''
    private id1 = ''
    private buildList = []
    private roomList = []
    private checkedByDelete: Array<string> = []

    created () {
      this.getBuildList()
      this.getData()
      if (this.searchInfo.buildingId) {
        this.changeBuild()
      }
    }

    getAuth (button: string) {
      const meau = JSON.parse(sessionStorage.getItem('childNode') || '{}')
      // 获取喷灌任务的id
      const index = meau.childNode.findIndex((item: any) => item.url === '/smartHouse/task')
      if (meau.childNode[index] && meau.childNode[index].button) {
        return meau.childNode[index].button.some((item: any) => item.butCode === button)
      }
      return false
    }

    // 楼栋
    getBuildList () {
      this.$axios.get(this.$apis.smartHouse.selectBuildingByList).then((res) => {
        this.buildList = res.list || []
      })
    }

    changeBuild () {
      this.searchInfo.roomId = ''
      if (this.searchInfo.buildingId) {
        this.getRoomList()
      } else {
        this.roomList = []
      }
    }

    // 房间
    getRoomList () {
      this.$axios.get(this.$apis.smartHouse.selectRoomByList, { buildingId: this.searchInfo.buildingId }).then((res) => {
        this.roomList = res.list || []
      })
    }

    // 查询
    searchData () {
      this.page = 1
      this.getData()
    }

    // 获取表格数据
    getData () {
      this.loading = true
      this.$axios.get(this.$apis.smartHouse.selectSqIrrigationPlanByPage, {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then((res) => {
        this.total = res.total || 0
        this.tableData = res.list || []
      }).finally(() => {
        this.loading = false
      })
    }

    change (id: string) {
      this.$axios.post(this.$apis.smartHouse.updateSqIrrigationPlanStatus, {
        planId: id
      }).then(() => {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.getData()
      })
    }

    // 批量删除
    onBatchDeletion () {
      if (!this.checkedByDelete.length) {
        this.$message({
          message: '请先选择你要删除的数据！',
          type: 'warning'
        })
        return false
      }
      this.$confirm(`共选中${this.checkedByDelete.length}条数据，确认删除吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.smartHouse.deleteAllSqIrrigationPlan, this.checkedByDelete).then(() => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.page = 1
          this.getData()
        })
      })
    }

    // 批量删除
    handleSelectionChange (val: Array<string>) {
      this.checkedByDelete = val.map((item: any) => item.planId)
      console.log(this.checkedByDelete)
    }

    onAdd () {
      this.id = ''
      this.showDialog = true
    }

    onUpdate (id: string) {
      this.showDialog = true
      this.id = id
    }

    success () {
      this.id = ''
      this.getData()
    }

    onDelete (id: string) {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.smartHouse.deleteSqIrrigationPlan, {
          planId: id
        }).then(() => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.page = 1
          this.getData()
        })
      })
    }

    onDetail (id: string) {
      this.id1 = id
      this.showDialog1 = true
    }
}
